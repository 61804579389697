import React from 'react'
import PropTypes from 'prop-types'
import { Box, Label, Input, Textarea, Button, Message, Spinner } from 'theme-ui'

const ContactForm = ({ handleSubmit, submitting, success }) => (
  <form
    onSubmit={handleSubmit}
    method='POST'
    action='YOUR_ACTION_END_POINT'
    demo='demo'
  >
    {success === true && (
      <Message variant='success'>
        Obrigado por entrar em contato. Em breve retornaremos!
      </Message>
    )}
    {success === false && (
      <Message variant='error'>
        Algo de errado aconteceu, tente novamente mais tarde!
      </Message>
    )}
    <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-name'>Nome</Label>
        <Input type='text' id='contact-form-name' name='name' required />
      </Box>
    </Box>
    <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-email'>Email</Label>
        <Input
          type='email'
          placeholder='email@exemplo.com'
          id='contact-form-email'
          name='email'
          required
        />
      </Box>
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-subject'>Título</Label>
      <Input type='text' id='contact-form-subject' name='subject' required />
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-message'>Descrição</Label>
      <Textarea name='message' id='contact-form-message' />
    </Box>
    <Button
      variant={success || submitting ? 'disabled' : 'primary'}
      disabled={success || submitting}
      type='submit'
      required
      value="Enviar"
    >
      Enviar {submitting && <Spinner size='20' />}
    </Button>
  </form>
)

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}